/*@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,800;0,900;1,100;1,300;1,400;1,500;1,800;1,900&display=swap');*/

* {
  font-family: Pretendard, 'Noto Sans KR', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.pending-home {
  color: #333333 !important;
  background-color: #333333 !important;
  animation-name: skeletonAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  border-radius: 5px;
}

@keyframes skeletonAnimation {
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.8;
  }
}
