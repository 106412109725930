@import "../../utils";

.footer {
  background: white;
  padding: 4rem 0;
  color: #111111;
  letter-spacing: -0.04em;

  > hr {
    border: none;
    background: #EFEFEF;
    height: 1px;
    margin-top: 60px;
    margin-bottom: 60px;

    @include media(">phone") {
      margin-top: 100px;
      margin-bottom: 60px;
    }
  }

  &.dark {
    color: white;
    background: #111111;

    > hr {
      background: #232323;
    }

    a {
      color: white;
    }

    .socialLinks {
      filter: none;
    }
  }

  .intro {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;

    @include media(">phone") {
      flex-direction: row;
      gap: 100px;
    }

    img {
      width: 220px;
    }

    h4 {
      font-size: 15.5px;
      font-weight: 900;
      margin-bottom: 0.5rem;

      @include media(">phone") {
        font-size: 17.5px;
      }
    }

    p {
      font-size: 14px;
      line-height: 22px;
      white-space: pre-line;

      @include media(">phone") {
        font-size: 16px;
        line-height: 26px;
      }
    }

    .aboutUs {
      margin-top: 20px;
      //max-width: 460px;

      @include media(">phone") {
        margin-top: 30px;
      }
    }

    .company, .contactUs, .socials {
      > h4 {
        margin-bottom: 20px;

        @include media(">phone") {
          margin-bottom: 30px;
        }
      }
    }
  }

  .textBlock {
    text-align: center;
    white-space: pre-line;
    color: #898989;
    font-size: 2vw;
    letter-spacing: -0.07em;

    @include media(">phone") {
      font-size: 15px;
      line-height: 18px;
    }
  }

  a {
    color: $secondary-color;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
