@use "sass:map";
@import "../../utils";

.fixedNav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: $nav-layout;
  height: map.get($nav-height, "phone");
  display: flex;
  align-items: center;

  @include media(">tablet") {
    height: map.get($nav-height, "tablet");
  }

  &.dark {
    background: #111111;
  }
}

.containerWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;

  a {
    display: flex;
    align-items: center;
  }

  .logo {
    width: 150px;

    @include media(">phone") {
      width: 200px;
    }
  }

  .leftSide {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-right: 15px;

    @include media(">tablet") {
      margin-right: 20px;
    }

    .menuButton {
      padding: 0;
      min-width: initial;
      color: #111111;
      font-size: 18px;

      @include media(">tablet") {
        font-size: 21px;
      }

      &.dark {
        color: white;
      }

      @include media(">phone") {
        display: none;
      }
    }

    ul {
      display: none;
      position: absolute;
      top: map.get($nav-height, "phone");
      left: 0;
      width: 100%;
      flex-direction: column;
      list-style: none;
      color: #111111;

      @include media(">tablet") {
        top: map.get($nav-height, "tablet");
      }

      &.menuOpen {
        display: flex;

        &.dark {
          color: #111111;
        }
      }

      &.dark {
        color: white;
      }

      @include media(">phone") {
        display: flex;
        position: relative;
        top: 0;
        left: 0;
        flex-direction: row;
        margin-left: 35px;
      }

      li {
        width: 100%;
        font-size: 16px;
        background: white;
        padding: 10px 20px;
        font-weight: 300;

        @include media(">phone") {
          width: fit-content;
          text-align: center;
          font-size: 20px;
          background: transparent;
          padding: initial;
          margin-right: 35px;
        }

        &.active {
          font-weight: 600;
        }
      }

      a {
        color: inherit;
        border-bottom: 2px solid transparent;
        padding-top: 2px;

        @include media(">phone") {
          &:hover {
            font-weight: 600;
            border-bottom: 2px solid #111111;
          }
        }
      }
    }
  }

  .rightSide {
    display: flex;
  }

  .loginButton {
    color: #111111;
    border: 2px solid #111111;
    border-radius: 20px;
    padding: 0 15px;
    text-transform: uppercase;
    transition: 0.3s;
    font-size: 12px;

    @include media(">tablet") {
      font-size: 14px;
    }

    &.dark {
      color: #BBFF00;
      border: 2px solid #BBFF00;

      &:hover {
        color: #111111;
        background-color: #BBFF00;
      }
    }

    &:hover {
      color: #BBFF00;
      background-color: #111111;
    }

    span {
      font-family: halogen, sans-serif !important;
      font-weight: 900 !important;
    }
  }
}
