@use "sass:map";
@import '~include-media/dist/include-media';

$breakpoints: (phone-m: 374px, phone: 568px, tablet: 768px, desktop: 992px);

$primary-color: #6402FF;
$secondary-color: #333333;

$nav-layout: 3;

$font-family-en: Pretendard, 'Inter', sans-serif;
$font-family-kr: Pretendard, 'Noto Sans KR', sans-serif;

$nav-height: (
  desktop: 72px,
  tablet: 72px,
  phone: 52px,
);

$spacing: (
  desktop: 50px,
  tablet: 28px,
  phone: 22px,
);

@mixin box-spacing {
  padding-right: map.get($spacing, "phone");

  @include media(">phone") {
    padding-right: map.get($spacing, "tablet");
  }

  @include media(">desktop") {
    padding-right: map.get($spacing, "desktop");
  }
}

@mixin responsive-margin-bottom($phone, $tablet, $desktop) {
  margin-bottom: $phone;

  @include media(">phone") {
    margin-bottom: $tablet;
  }

  @include media(">desktop") {
    margin-bottom: $desktop;
  }
}
